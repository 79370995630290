import React from 'react'
import styled from 'styled-components';
import { Button } from '../components/Button';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import logo from "../images/logo.svg"
import { GlobalStyle } from '../components/styles/GlobalStyles';
import SEO from '../components/seo';


const Pharmacy_LandingPage = () => {
    const data = useStaticQuery(graphql`
        query PharmacyLandingPageQuery {
            allLandingPagesJson {
            edges {
                node {
                alt6
                img6 {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
            }
        }
      
    `)

    const getImage = (data) => {
        const imageArray = []
        data.allLandingPagesJson.edges.forEach((element, index) => {
            imageArray.push(
                <div key={index}>
                    <Image
                        alt={element.node.alt6}
                        fluid={element.node.img6.childImageSharp.fluid}>
                    </Image>

                </div>
            )
        })
        return imageArray
    }

    return (
        <Container>
            <ContainerHero>
                <SEO title="Online Pharmacy and Management Platform"
                    description="Online Pharmacy and Management Platform demo"
                    keywords="Online Pharmacy and Management Platform"
                    img="https://www.tripleaimsoftware.com/static/684d52597840830b8507e00c13da0455/ee604/pharmacyUC.png"

                >
                </SEO>
                <GlobalStyle />
                <img src={logo} alt="logo" css={`width: 130px; height: 130px;position: absolute; top: -30px; left: 10px`} />
                <CtaWrapper>
                    <ContainerP>Futureproof Your Pharmacy Business Today</ContainerP>
                    <ContainerP2>1. Pharmacy management software with modern e-Commerce platform</ContainerP2>
                    <ContainerP3> <span role="img" aria-label="point"> 👉 </span> Branded customer e-commerce pharmacy storefront</ContainerP3>
                    <ContainerP3> <span role="img" aria-label="point"> 👉 </span>Inventory Management module</ContainerP3>
                    <ContainerP3> <span role="img" aria-label="point"> 👉 </span>Staff Management module</ContainerP3>
                    <ContainerP3> <span role="img" aria-label="point"> 👉 </span>Pharmacy Management module</ContainerP3>
                    <ContainerP3> <span role="img" aria-label="point"> 👉 </span>Web dashboards for managers</ContainerP3>
                    <ContainerP2>2. Web-based platform with unlimited users</ContainerP2>
                    <ContainerP2>3. At a basic rebranding cost and a monthly subscription</ContainerP2>
                    <ContainerP2>4. Delivered within 28 days</ContainerP2>
                    <form name="website sign up" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                        <input type="hidden" name="form-name" value="website sign up" />
                        <FormWrap>
                            <label htmlFor="email">
                                <ContainerP3>Enter Your Email Below for a Demo</ContainerP3>
                                <input type="email" id="email" name="email" />
                            </label>
                            <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Schedule a Demo</Button>

                        </FormWrap>
                    </form>
                </CtaWrapper>
                <Hero>
                    {getImage(data)}
                </Hero>
            </ContainerHero>
            <ContainerInfo>
                <ContainerHeaderInfo>Benefits</ContainerHeaderInfo>
                <ContainerPInfo>Attract new customers with an engaging, interactive e-commerce pharmacy website.</ContainerPInfo>
                <ContainerPInfo>Improve your customer service experience by enabling them to order prescriptions, and buy products online.</ContainerPInfo>
                <ContainerPInfo>Improve efficiency, productivity, and cost reduction with our pharmacy management software.</ContainerPInfo>
                <ContainerHeaderInfo>How</ContainerHeaderInfo>
                <ContainerPInfo>You need a professional e-Commerce website that becomes the hub of your online brand and allows you to futureproof your pharmacy business.</ContainerPInfo>
                <ContainerPInfo>You don’t have to go “all-in” on e-Commerce straight away, but you do need to prevent the big pharmacy chains from stealing sales from your retail customers who are already shopping online.</ContainerPInfo>
                <ContainerPInfo>You should create a professional, modern online experience for your local retail customers so when they are shopping online, they can keep their business with their local pharmacy.</ContainerPInfo>
                <ContainerHeaderInfo>Pricing</ContainerHeaderInfo>
                <ContainerPInfo>We offer three packages that will help move your pharmacy business online, enhance your customers’ experience and allow you to start selling online quickly..</ContainerPInfo>
                <ContainerHeaderInfo>Why Us</ContainerHeaderInfo>
                <ContainerPInfo>As a pharmacist-owned software company, we know your pharmacy business and your customers' needs better than anyone else.</ContainerPInfo>
                <ContainerPInfo>This understanding allows us to seamlessly manage to design and develop your e-Commerce pharmacy website from start to finish while enabling you to run your pharmacy business.</ContainerPInfo>
                <ContainerHeaderInfo>Why now</ContainerHeaderInfo>
                <ContainerPInfo>The global pandemic has accelerated the need for you to futureproof your pharmacy.</ContainerPInfo>
                <ContainerPInfo>The growth of pharmacy chains with a substantial online presence creates pressure on retail pharmacies. To ensure you survive and thrive, get visible online.</ContainerPInfo>
                <ContainerPInfo>Reduce the high cost of doing business by leveraging your online platform.</ContainerPInfo>
            </ContainerInfo>
            <form name="website sign up" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="website sign up" />
                <FormWrap css={`width: 70%; margin-left: auto; margin-right: auto;`}>
                    <label htmlFor="email">
                        <ContainerP3 css={`text-align: center`}>Enter Your Email Below for a Demo</ContainerP3>
                        <input type="email" id="email" name="email" />
                    </label>
                    <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Schedule a Demo</Button>

                </FormWrap>
            </form>
        </Container>
    )
}

export default Pharmacy_LandingPage

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    
`
const ContainerHero = styled.div`
    display: grid;
    width: 100%;
    max-height: 100%;
    min-height: 100vh;
    grid-template-columns: repeat(12,1fr);
    grid-auto-rows: auto;   
    //background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
`
const CtaWrapper = styled.div`
    grid-row: 2;
    grid-column: span 12;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    //align-self: center;

    @media screen and (min-width: 800px) {
        grid-row: 2;
        grid-column: span 6;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-self: center;
        //max-width: 80%;
        margin-top: 3.5rem;
    }
                
    @media screen and (min-width: 1200px) {
        grid-row: 2;
        grid-column: span 5;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-self: center;
        max-width: 80%;
    }
`
const Hero = styled.div`
    grid-column: span 12;
    grid-row: 1;
    margin-top: 3rem;

    @media screen and (min-width: 600px) {
        grid-column: span 12;
        grid-row: 1;
        //margin-top: -1rem;
    }

    @media screen and (min-width: 800px) {
        grid-column: span 6;
        grid-row: 2;
    }
    
    @media screen and (min-width: 1200px) {
        grid-column: span 7;
        grid-row: 2;
    }

`

const Image = styled(Img)`
    width:100%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 600px) {
        width: 75%;    
       }    

    @media screen and (min-width: 800px) {
     width:100%;
         
    }
    @media screen and (min-width: 1200px) {
     width:90%;
         
    }

`
const ContainerP = styled.h1`
    font-size: clamp(1.5rem,2vw, 2rem);
    //text-align: center;
    color: #fff;
    margin-bottom: 1rem;
`
const ContainerP2 = styled.p`
    font-size: clamp(0.9rem,2vw, 1rem);
    //text-align: center;
    color: #fff;
    margin-bottom: 1rem;
`
const ContainerP3 = styled.p`
    font-size: clamp(0.8rem,1vw, 1rem);
    //text-align: center;
    color: #fff;
    margin-left: 2rem;
`
const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    

    @media screen and (min-width: 800px) {
        max-width: 80%;
            
       }
       @media screen and (min-width: 1200px) {
        max-width: 80%;
            
       }

 input {
     padding: 1rem 1,5rem;
     outline: none;
     width: 100%;
     height: 50px;
     border-radius: 20px;
     border: 2px solid #71C4DE;
     margin-bottom: 1rem;
     text-align: center;
 }
`
const ContainerInfo = styled.div`
     //margin-top: -5rem;
     width: 90%;
     color: #fff;
     margin-left: auto;
     margin-right: auto;

     @media screen and (min-width: 800px) {
        margin-top: -3rem;
        width: 70%;
        color: #fff;
        margin-left: auto;
        margin-right: auto;
    }
                
    @media screen and (min-width: 1200px) {
        margin-top: -5rem;
        width: 70%;
        color: #fff;
        margin-left: auto;
        margin-right: auto;
    }
`
const ContainerHeaderInfo = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem; 
    text-align: center;
`
const ContainerPInfo = styled.p`
    text-align: center;
    font-size: clamp(1rem,3vw, 1.25rem); 
    padding: 0rem 1rem;
    margin-bottom: 2rem;
`

